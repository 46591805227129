import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Research from "../images/research.webp";
import Design from "../images/design.webp";
import Build from "../images/build.webp";
import Dots from "../images/process-dots.webp";
import Aos from "aos";
import "aos/dist/aos.css";

const Process = (props) => {
  useEffect(() => {
    Aos.init({});
  }, []);

  return (
    <>
      <img src={Dots} className="dots hide-on-med-and-down" alt="Dots" />

      <div className="container center-align hide-on-med-and-down">
        <div className="row process-design">
          <img className="process-image" src={Research} alt="Research" />
          <hr className="process-line" />
          <img className="process-image" src={Design} alt="Design" />
          <hr className="process-line" />
          <img className="process-image" src={Build} alt="Build" />
        </div>
        <div className="row">
          <div className="col l4">
            <h6 className="medium">Deep Dive</h6>
            <p>
              No matter the industry (government, non-profit, or private
              sector), we dive deep into your data, resources, and people to
              learn what makes your organization unique.
            </p>
          </div>

          <div className="col l4">
            <h6 className="medium">Develop</h6>
            <p>
              Taking what we've learned in the deep dive phase, we design custom
              solutions and products based on your specific business needs.
            </p>
          </div>
          <div className="col l4">
            <h6 className="medium">Deploy</h6>
            <p>
              Even after building your solution, VISIMO is still your partner.
              We collaborate with all parts of your business to ensure a
              successful deployment. We measure success by your satisfaction.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, actions)(Process);
