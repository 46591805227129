export const CONTACT_SUCCESS = "contact_success";

export const CONTACT_ERROR = "contact_error";

export const FETCH_MEDIA = "fetch_media";

export const FETCH_AWARDS_SQUARE = "fetch_awards_square";
export const FETCH_AWARDS_RECTANGLE = "fetch_awards_rectangle";
export const FETCH_CLIENTS = "fetch_clients";
export const FETCH_PARTNERS = "fetch_partners";
