import {
  FETCH_CLIENTS,
  FETCH_PARTNERS,
  FETCH_AWARDS_SQUARE,
  FETCH_AWARDS_RECTANGLE,
} from "../actions/types";

export default function (
  state = { partners: [], clients: [], awardsSquare: [], awardsRectangle: [] },
  action
) {
  switch (action.type) {
    case FETCH_PARTNERS:
      return Object.assign({}, state, {
        partners: action.payload,
      });
    case FETCH_CLIENTS:
      return Object.assign({}, state, {
        clients: action.payload,
      });
    case FETCH_AWARDS_SQUARE:
      return Object.assign({}, state, {
        awardsSquare: action.payload,
      });
    case FETCH_AWARDS_RECTANGLE:
      return Object.assign({}, state, {
        awardsRectangle: action.payload,
      });
    default:
      return state;
  }
}
