import { connect } from "react-redux";
import * as actions from "./../../actions";
import "./moreInfo.css";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import NavBar from "./../../specialties/navBar/navBar.js";

const Article = ({ articleList }) => {
  return (
    <div>
      <NavBar />
      <div className="row" style={{ zIndex: "-1" }}>
        {/* header */}
        <div
          className="col s12 backImage"
          style={{ height: "600px", position: "relative" }}
        >
          <h1
            style={{
              position: "absolute",
              top: "35%",
              left: "10%",
              right: "30%",
            }}
          >
            {" "}
            {articleList.title}{" "}
          </h1>
          <span className="mobileNo">
            <p
              style={{
                fontWeight: "bold",
                position: "absolute",
                bottom: "15%",
                left: "11%",
              }}
            >
              {" "}
              {articleList.date}{" "}
            </p>
          </span>
          <span className="mobileYes">
            <p
              style={{
                fontWeight: "bold",
                position: "absolute",
                bottom: "15%",
                left: "11%",
              }}
            >
              {" "}
              {articleList.date}{" "}
            </p>
          </span>
        </div>
        {/* actual info */}
        <div
          className="col s12 white"
          style={{ position: "relative", padding: "3% 5%" }}
        >
          <h5 style={{ paddingTop: "50px" }}> {articleList.title}. </h5>
          {ReactHtmlParser(articleList.body)}
        </div>
        {/* make next post portion here*/}
      </div>
    </div>
  );
};

export default connect(null, actions)(Article);
