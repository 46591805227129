import React, { Component } from "react";
import axios from "axios";
import "./who-we-are.css";
import { apiUrl } from "../config";

class TeamMemberIpad extends Component {
  constructor() {
    super();
    this.state = {
      persons: [],
      disp: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    axios({
      method: "GET",
      url: `${apiUrl}/employees/`,
    }).then((res) => {
      var index = 1;

      const staff = res.data.map((person) => {
        index = (index + 1) % 2;

        if (index === 0) {
          return (
            <div>
              <div
                className="col s6 white"
                style={{ height: "420px", position: "relative" }}
              >
                <div
                  className="checker"
                  style={{
                    position: "absolute",
                    top: "0",
                    height: "400px",
                    width: "68%",
                    right: "15%",
                  }}
                >
                  <div
                    className="overlay"
                    style={{ position: "absolute", top: "0", zIndex: "3" }}
                  >
                    <p
                      style={{
                        position: "absolute",
                        color: "white",
                        margin: "0",
                        padding: "14%",
                        height: "300px",
                        overflowY: "scroll",
                        zIndex: "50",
                        fontWeight: "bold",
                      }}
                      className="opacityFix"
                    >
                      {" "}
                      {person.description}{" "}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    backgroundImage: `linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, .5),
                    rgba(0, 0, 0, .2),
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                    ), url(${person.picture})`,
                    backgroundSize: "cover",
                    position: "absolute",
                    top: "0",
                    height: "400px",
                    width: "68%",
                    right: "15%",
                    zIndex: "1",
                  }}
                />
                <div
                  style={{
                    width: "48%",
                    backgroundImage:
                      "linear-gradient(to left, #01aed6, rgba(0, 0, 0, 0))",
                    height: "7px",
                    position: "absolute",
                    right: "15%",
                    zIndex: 2,
                  }}
                />
                <p
                  style={{
                    position: "absolute",
                    color: "white",
                    zIndex: "1",
                    top: "1%",
                    left: "25%",
                    textTransform: "uppercase",
                    right: "25%",
                  }}
                >
                  {person.position}
                </p>
                <h5
                  style={{
                    position: "absolute",
                    color: "rgb(230, 227, 227)",
                    zIndex: "4",
                    top: "76%",
                    left: "25%",
                    textTransform: "uppercase",
                  }}
                >
                  {" "}
                  {person.name.split(" ", 1)}{" "}
                </h5>
                <h5
                  style={{
                    position: "absolute",
                    color: "rgb(230, 227, 227)",
                    zIndex: "4",
                    top: "81%",
                    left: "25%",
                    textTransform: "uppercase",
                  }}
                >
                  {" "}
                  {person.name.slice(person.name.lastIndexOf(" ") + 1)}{" "}
                </h5>
              </div>
            </div>
          );
        }

        if (index === 1) {
          return (
            <div>
              <div
                className="col s6 white"
                style={{ height: "420px", position: "relative" }}
              >
                <div
                  className="checker"
                  style={{
                    position: "absolute",
                    top: "0",
                    height: "400px",
                    width: "68%",
                    right: "15%",
                  }}
                >
                  <div
                    className="overlay"
                    style={{ position: "absolute", top: "0", zIndex: "3" }}
                  >
                    <p
                      style={{
                        position: "absolute",
                        color: "white",
                        margin: "0",
                        padding: "14%",
                        height: "300px",
                        overflowY: "scroll",
                        zIndex: "50",
                        fontWeight: "bold",
                      }}
                      className="opacityFix"
                    >
                      {" "}
                      {person.description}{" "}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    backgroundImage: `linear-gradient(
	              to bottom,
	              rgba(0, 0, 0, .5),
	              rgba(0, 0, 0, .2),
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
                ), url(${person.picture})`,
                    backgroundSize: "cover",
                    position: "absolute",
                    top: "0",
                    height: "400px",
                    width: "68%",
                    right: "15%",
                    zIndex: "1",
                  }}
                />
                <div
                  style={{
                    width: "48%",
                    backgroundImage:
                      "linear-gradient(to left, #01aed6, rgba(0, 0, 0, 0))",
                    height: "7px",
                    position: "absolute",
                    right: "15%",
                    zIndex: 2,
                  }}
                />
                <p
                  style={{
                    position: "absolute",
                    color: "white",
                    zIndex: "1",
                    top: "1%",
                    left: "25%",
                    textTransform: "uppercase",
                    right: "25%",
                  }}
                >
                  {person.position}
                </p>
                <h5
                  style={{
                    position: "absolute",
                    color: "rgb(230, 227, 227)",
                    zIndex: "4",
                    top: "76%",
                    left: "25%",
                    textTransform: "uppercase",
                  }}
                >
                  {" "}
                  {person.name.split(" ", 1)}{" "}
                </h5>
                <h5
                  style={{
                    position: "absolute",
                    color: "rgb(230, 227, 227)",
                    zIndex: "4",
                    top: "81%",
                    left: "25%",
                    textTransform: "uppercase",
                  }}
                >
                  {" "}
                  {person.name.slice(person.name.lastIndexOf(" ") + 1)}{" "}
                </h5>
              </div>
            </div>
          );
        }

        return null;
      });
      this.setState({ persons: staff });
    });
  }

  render() {
    if (this.state.persons === null) {
      return <p> loading, please wait ...</p>;
    } else {
      return (
        <>
          <div className="row">
            <div className="col s12 white" style={{ position: "relative" }}>
              {this.state.persons}
            </div>
          </div>
        </>
      );
    }
  }
}

export default TeamMemberIpad;
