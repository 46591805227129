import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./../../actions";
import "./moreInfo.css";
import React, { Component } from "react";
import axios from "axios";
import { apiUrl } from "../../config";

class NextInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: [],
    };
  }

  componentDidMount() {
    axios({
      method: "GET",
      url: `${apiUrl}/media/${parseInt(this.props.currentPage)}/next/${parseInt(
        this.props.currentPage
      )}/`,
    }).then((res) => {
      this.setState({ article: res.data });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentPage !== prevProps.currentPage) {
      axios({
        method: "GET",
        url: `${apiUrl}/media/${parseInt(
          this.props.currentPage
        )}/next/${parseInt(this.props.currentPage)}/`,
      }).then((res) => {
        this.setState({ article: res.data });
      });
    }
  }

  render() {
    if (this.props.currentPage == null) {
      return <p> loading, please wait ...</p>;
    } else if (this.state.article.length === 0) {
      return null;
    } else {
      return (
        <div>
          <div className="row">
            <div
              className="col s12 white"
              style={{
                position: "relative",
                "border-top": "1px solid",
                height: "500px",
              }}
            >
              <h5
                style={{
                  position: "absolute",
                  color: "#01AED6",
                  left: "10%",
                  top: "10%",
                }}
              >
                {" "}
                Next Page{" "}
              </h5>
              <h5 style={{ position: "absolute", left: "10%", top: "30%" }}>
                {this.state.article[0].title}
              </h5>
              <p
                style={{
                  position: "absolute",
                  left: "10%",
                  top: "50%",
                  right: "10%",
                }}
              >
                {" "}
                {this.state.article[0].description}
              </p>
              <p
                style={{
                  position: "absolute",
                  color: "grey",
                  fontWeight: "bold",
                  right: "10%",
                  bottom: "5%",
                }}
              >
                {" "}
                {this.state.article[0].date}
              </p>
              <Link to={`/media/${this.state.article[0].id}`}>
                <p
                  style={{
                    fontSize: "40px",
                    position: "absolute",
                    color: "red",
                    top: "-5%",
                    right: "10%",
                  }}
                >
                  {" "}
                  +{" "}
                </p>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default connect(null, actions)(NextInfo);
