import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import App from "./App";
import rootReducer from "./reducers/index";
import "materialize-css/dist/css/materialize.min.css";
import "./index.css";
import "animate.css/source/animate.css";
import "./fonts/Heebo/Heebo-Black.ttf";
import React from "react";
import Contact from "./contactPage/contactPage";
import WhoWeAre from "./who-we-are/who-we-are";
import CaseStudies from "./case-studies/case-studies";
import Media from "./media/media.js";
import MediaInfo from "./media/moreInfo/moreInfo.js";
import CaseInfo from "./case-studies/landingPage.js";
import Career from "./careers/careers";

const store = createStore(rootReducer, {}, applyMiddleware(ReduxThunk));

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
        <Route exact path="/who-we-are" component={WhoWeAre} />
        <Route exact path="/case-studies" component={CaseStudies} />
        <Route exact path="/media" component={Media} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/careers" component={Career} />
        <Route path="/media/:slug" component={MediaInfo} />
        <Route path="/case-studies/:slug" component={CaseInfo} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
