import React from "react";
import { connect } from "react-redux";
import * as actions from "./../../actions";
import { Link } from "react-router-dom";

const Featured = ({ articleData }) => {
  const articleList = articleData.map((article) => {
    return (
      <div>
        {article.featured && (
          <li
            key={article.id}
            style={{
              color: "#FFF",
              position: "absolute",
              top: "6%",
              left: "10%",
            }}
          >
            <p style={{ fontWeight: "bold", fontSize: "20px" }}>
              {" "}
              Featured Post{" "}
            </p>
            <Link to={`/media/${article.id}`}>
              <p
                style={{
                  fontSize: "40px",
                  position: "absolute",
                  top: "-5%",
                  right: "10%",
                  color: "red",
                }}
                className="spinner"
              >
                {" "}
                +{" "}
              </p>
            </Link>
            <span className="mobileNo">
              <Link to={`/media/${article.id}`}>
                <h1 style={{ paddingTop: "20px", color: "#FFF" }}>
                  {" "}
                  {article.title}{" "}
                </h1>
              </Link>
            </span>
            <span className="mobileYes">
              <h4 style={{ paddingTop: "20px" }}> {article.title} </h4>
            </span>
            <p> {article.description} </p>
            <p> {article.date} </p>
          </li>
        )}
      </div>
    );
  });

  return (
    <>
      <div>
        <ul>{articleList}</ul>
      </div>
    </>
  );
};

export default connect(null, actions)(Featured);
