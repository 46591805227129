import React from "react";

import { connect } from "react-redux";
import * as actions from "../actions";

class ContactForm extends React.Component {
  //constructs a new state for the new contact
  constructor() {
    super();

    this.state = {
      name: "",
      email: "",
      org: "",
      message: "",
    };
    //binds the functions
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  //called when user hits submit
  handleSubmit() {
    //sets the details for the new contact
    const newContact = {
      name: this.state.name,
      email: this.state.email,
      org: this.state.org,
      message: this.state.message,
    };

    this.props.postContact(newContact);
  }

  render() {
    return (
      <>
        <div className="row">
          <form className="col s12">
            <div className="row">
              <div className="input-field col s12">
                <input
                  id="name"
                  type="text"
                  className="validate"
                  placeholder="FULL NAME"
                  onChange={(event) =>
                    this.setState({ name: event.target.value })
                  }
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12">
                <input
                  id="email"
                  type="email"
                  className="validate"
                  placeholder="EMAIL"
                  onChange={(event) =>
                    this.setState({ email: event.target.value })
                  }
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12">
                <input
                  id="org"
                  type="text"
                  placeholder="ORGANIZATION"
                  onChange={(event) =>
                    this.setState({ org: event.target.value })
                  }
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12">
                <textarea
                  id="message"
                  className="materialize-textarea"
                  type="text"
                  placeholder="MESSAGE"
                  onChange={(event) =>
                    this.setState({ message: event.target.value })
                  }
                ></textarea>
              </div>
            </div>
            <button
              type="submit"
              style={{ margin: "0px" }}
              className="btn waves-effect waves-light"
              onClick={this.handleSubmit}
            >
              Submit
              <i className="material-icons right">arrow_forward</i>
            </button>
            <br />
            <br />
            <br />
            <br />
          </form>
        </div>
      </>
    );
  }
}

export default connect(null, actions)(ContactForm);
