import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Awards from "../careers/awards";

const Logos = ({ partners, fetchLogos }) => {
  React.useEffect(() => {
    fetchLogos();
  }, [fetchLogos]);

  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "100px",
        marginRight: "auto",
        marginLeft: "auto",
        width: "90%",
      }}
    >
      <h3>
        Who We Work <span className="outline">With</span>.
      </h3>
      <div className="row">
        {partners.map((logo) => {
          return (
            <div
              style={{
                width: "150px",
                display: "inline-block",
                verticalAlign: "middle",
              }}
            >
              <img
                style={{ width: "90%", height: "auto", margin: "auto" }}
                src={logo.picture}
                alt={logo.name}
              />
            </div>
          );
        })}
      </div>
      <Awards />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    partners: state.logos.awardsSquare,
    clients: state.logos.clients,
  };
};

export default connect(mapStateToProps, actions)(Logos);
