import React from "react";

const Testimonies = () => {
  return (
    <div className="container" style={{ textAlign: "left", marginTop: "50px" }}>
      <div className="row">
        <div
          className="s12"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="iframe-container">
            <iframe
              src="https://player.vimeo.com/video/582950543"
              frameborder="0"
              className="responsive-iframe"
              allow="autoplay; fullscreen; picture-in-picture"
              aallowfullscreen="allowfullscreen"
              mozallowfullscreen="mozallowfullscreen"
              msallowfullscreen="msallowfullscreen"
              oallowfullscreen="oallowfullscreen"
              webkitallowfullscreen="webkitallowfullscreen"
              title="Testimonials Video"
            ></iframe>
          </div>
        </div>
        <div className="col l6 m6 s12" style={{ padding: "25px" }}>
          <h6>
            "I want to thank you for all of your hard work, as well as the hard
            work of the rest of the VISIMO team. It's great to be able to
            connect [others] with VISIMO in order to get these data. I also hope
            this connection is the start of many more research efforts...one
            good thing to come out of the mess of 2020/2021 is our fruitful
            collaboration with VISIMO."
          </h6>
          <br />
          <h6>- Beth Hoffman </h6>
          <p style={{ width: "75%", fontSize: "12px" }}>
            MPH, Center for Social Dynamics and Community Health, University of
            Pittsburgh - Graduate School of Public Health
          </p>
        </div>
        <div className="col l6 m6 s12" style={{ padding: "25px" }}>
          <h6>
            "You and your team were outstanding to work with on putting together
            this pre-proposal. Thank you for working through the ideas with us.
            I am looking forward to working with you all when we win the
            project."
          </h6>
          <br />
          <h6>- Dr. Phillip Mulligan</h6>
          <p style={{ width: "75%", fontSize: "12px" }}>
            Assistant Research Professor, Geosciences & Geological & Petroleum
            Engineering, Missouri University of Science & Technology - Rock
            Mechanics & Explosives Research Center
          </p>
        </div>
      </div>
    </div>
  );
};

export default Testimonies;
