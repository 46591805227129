import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import ContactForm from "./contactForm";
import "aos/dist/aos.css";
import Waves from "../images/VISIMO_Digital_Wave_White.webp";

const GetInTouch = () => {
  return (
    <div className="row  large-margin " style={{ position: "relative" }}>
      <span className="breakDown">
        <img
          src={Waves}
          style={{
            position: "absolute",
            bottom: "0",
            left: "0",
            height: "800px",
            width: "30%",
          }}
          alt="Waves"
        />
      </span>
      <div className="col l8 ">
        <div className="col l4 dot-col hide-on-med-and-down">
          <div className="side-tab">
            <hr />
            <p className="gray tab"> CONTACT</p>
          </div>
        </div>
        <div className="col l7">
          <h1>Get In</h1>
          <h1>
            <span className="outline">Touch</span>.
          </h1>
          <p>
            Have questions? Need help demystifying your data? Interested in
            joining an innovative culture that strives to disrupt the world of
            data analytics? Reach out and say, "hi."{" "}
          </p>
          <ContactForm />
        </div>
      </div>
      <div className="col l4 center-align getInTouch-image-wrapper"></div>
    </div>
  );
};

export default connect(null, actions)(GetInTouch);
