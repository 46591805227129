import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import NavBar from "../navBar/navBar.js";
import Footer from "../footer/footer.js";
import "./media.css";
import Header from "./header/header.js";
import Featured from "./featured/featured.js";
import NonFeatured from "./nonFeatured/nonFeatured.js";

class Media extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <NavBar />
        <Header />
        <Featured />
        <NonFeatured />
        <Footer />
      </div>
    );
  }
}

export default connect(null, actions)(Media);
