import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import "./who-we-are.css";

const MissionStatement = (props) => {
  return (
    <div className="container large-padding ">
      <h1 className="col l9 title">
        VISIMO develops state-of-the-art data science tools and builds custom,
        interactive, and actionable cloud-based software
        <span className="period">.</span>{" "}
      </h1>
      <br />
      <h1 className="col l9 title ">
        Our technological expertise enables us to create comprehensive and
        innovative solutions that are open-source, secure, and accessible on any
        device from anywhere in the world
        <span className="period">.</span>
      </h1>
    </div>
  );
};

export default connect(null, actions)(MissionStatement);
