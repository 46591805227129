import React, { useEffect } from "react";
import Stamp from "../images/VISIMO_Stamp_Blue.webp";
import { connect } from "react-redux";
import * as actions from "../actions";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Approach = (props) => {
  useEffect(() => {
    Aos.init({});
  }, []);

  return (
    <div className="row  large-margin approach-padding">
      <div className="col l2 m1 vertical hide-on-med-and-down ">
        <p className="gray rotate">
          <span>
            <hr />
          </span>{" "}
          WHO WE ARE
        </p>
      </div>
      <div className="col l5 m12 approach-text-padding">
        <h1>Your Data.</h1>
        <h1>
          Our <span className="outline">Approach</span>.
        </h1>
        <p>
          We take a human-centered analytic approach to everything we do. We’re
          disruptive. We're young. We're innovative. And we won't rest until we
          find the solution to your business needs.
        </p>
        <Link to="/who-we-are">
          <button
            className="btn waves-effect waves-light"
            style={{ margin: "0px" }}
            type="submit"
            name="action"
          >
            Learn More
            <i className="material-icons right">arrow_forward</i>
          </button>
        </Link>
      </div>

      <div className="col l5 approach-stamp">
        <div
          data-aos="slide-up"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="400"
          data-aos-offset="250"
          className=" approach-image-wrapper"
        ></div>

        <img
          src={Stamp}
          className="bottom-left-position"
          alt="VISIMO Stamp Logo"
        />
      </div>
    </div>
  );
};

export default connect(null, actions)(Approach);
