import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";

const Logos = ({ awardsSquare, awardsRectangle }) => {
  return (
    <>
      <h3>
        Affiliations and <span className="outline">Awards</span>.
      </h3>
      <div className="row">
        {awardsSquare.map((logo) => {
          return (
            <div
              style={{
                width: "150px",
                display: "inline-block",
                verticalAlign: "middle",
              }}
            >
              <img
                style={{ width: "90%", height: "auto", margin: "auto" }}
                src={logo.picture}
                alt={logo.name}
              />
            </div>
          );
        })}
        {awardsRectangle.map((logo) => {
          return (
            <div
              style={{
                width: "300px",
                display: "inline-block",
                verticalAlign: "middle",
              }}
            >
              <img
                style={{ width: "90%", height: "auto", margin: "auto" }}
                src={logo.picture}
                alt={logo.name}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    awardsSquare: state.logos.partners,
    awardsRectangle: state.logos.awardsRectangle,
  };
};

export default connect(mapStateToProps, actions)(Logos);
