import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "./../../actions";
import "./nonFeatured.css";
import BlueFade from "../../images/rotateBlueFade.webp";
import axios from "axios";
import Articles from "./articleComp.js";
import { apiUrl } from "../../config";

class SubFeatured extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
    };
  }

  componentDidMount() {
    axios({
      method: "GET",
      url: `${apiUrl}/media/`,
    }).then((res) => {
      this.setState({ articles: res.data });
    });
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col s12 white" style={{ position: "relative" }}>
            <img
              src={BlueFade}
              style={{
                position: "absolute",
                right: "0",
                bottom: "0",
                margin: "0",
                padding: "0",
                height: "20px",
                width: "35%",
                transform: "rotate(180deg)",
              }}
              alt="Blue Fade"
            />
            <Articles articleData={this.state.articles} />
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, actions)(SubFeatured);
