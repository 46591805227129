import React from "react";
import { connect } from "react-redux";
import * as actions from "./../actions";
import LinkedIn from "../images/linkedIn.webp";
import TeamMemberMobile from "./teamMemberMobile.js";
import "./who-we-are.css";
import TeamMemberIpad from "./teamMemberIpad.js";
import TeamMemberBetween from "./teamMemberInBetween.js";

const Team = ({ teamData }) => {
  var index = 2;

  const staff = teamData.map((person) => {
    index = (index + 1) % 3;

    const staffLinkedIn = (
      <a href={person.linkedin} target="blank">
        <img
          src={LinkedIn}
          style={{
            width: "30px",
            height: "auto",
            position: "absolute",
            top: "70%",
            left: "15%",
            zIndex: "50",
          }}
          alt="Linkedin"
        />
      </a>
    );

    if (index === 0) {
      return (
        <div>
          <div
            className="col s5 white"
            style={{ height: "420px", position: "relative" }}
          >
            <div
              className="checker"
              style={{
                position: "absolute",
                top: "0",
                height: "400px",
                width: "48%",
                right: "15%",
              }}
            >
              <div
                className="overlay"
                style={{ position: "absolute", top: "0", zIndex: "3" }}
              >
                <p
                  style={{
                    position: "absolute",
                    margin: "0",
                    overflowY: "scroll",
                    padding: "14%",
                    height: "275px",
                    color: "white",
                    zIndex: "50",
                    "font-weight": "bold",
                  }}
                  className="opacityFix"
                >
                  {person.description}
                </p>
                {staffLinkedIn}
              </div>
            </div>
            <div
              style={{
                width: "48%",
                backgroundImage:
                  "linear-gradient(to left, #01aed6, rgba(0, 0, 0, 0))",
                height: "7px",
                position: "absolute",
                right: "15%",
                zIndex: 2,
              }}
            />
            <div
              style={{
                backgroundImage: `linear-gradient(
	              to bottom,
	              rgba(0, 0, 0, .5),
	              rgba(0, 0, 0, .2),
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
                ), url(${person.picture})`,
                backgroundSize: "cover",
                position: "absolute",
                top: "0",
                height: "400px",
                width: "48%",
                right: "15%",
                zIndex: "1",
              }}
            />
            <p
              style={{
                width: "200px",
                position: "absolute",
                color: "white",
                zIndex: "1",
                top: "1%",
                left: "44%",
                textTransform: "uppercase",
                right: "20%",
              }}
            >
              {person.position}
            </p>
            <h5
              style={{
                position: "absolute",
                color: "rgb(230, 227, 227) ",
                zIndex: "4",
                top: "71%",
                left: "44%",
                textTransform: "uppercase",
              }}
            >
              {" "}
              {person.name.split(" ", 1)}{" "}
            </h5>
            <h5
              style={{
                position: "absolute",
                color: "rgb(230, 227, 227) ",
                zIndex: "4",
                top: "76%",
                left: "44%",
                textTransform: "uppercase",
              }}
            >
              {" "}
              {person.name.slice(person.name.lastIndexOf(" ") + 1)}{" "}
            </h5>
          </div>
        </div>
      );
    }

    if (index === 1) {
      return (
        <div
          className="col s2"
          style={{ height: "420px", position: "relative" }}
        >
          <div
            className="checker"
            style={{
              position: "absolute",
              top: "9%",
              height: "400px",
              width: "120%",
              right: "-10%",
            }}
          >
            <div
              className="overlay"
              style={{ position: "absolute", top: "0", zIndex: "3" }}
            >
              <p
                style={{
                  position: "absolute",
                  margin: "0",
                  padding: "14%",
                  overflowY: "scroll",
                  height: "275px",
                  color: "white",
                  zIndex: "50",
                  "font-weight": "bold",
                }}
                className="opacityFix"
              >
                {" "}
                {person.description}{" "}
              </p>
              {staffLinkedIn}
            </div>
          </div>
          <div
            style={{
              backgroundImage: `linear-gradient(
	              to bottom,
	              rgba(0, 0, 0, .5),
	              rgba(0, 0, 0, .2),
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
                ), url(${person.picture})`,
              backgroundSize: "cover",
              position: "absolute",
              top: "9%",
              height: "400px",
              width: "120%",
              right: "-10%",
              zIndex: "1",
            }}
          />
          <div
            style={{
              width: "120%",
              backgroundImage:
                "linear-gradient(to left, #01aed6, rgba(0, 0, 0, 0))",
              height: "7px",
              position: "absolute",
              top: "9%",
              right: "-10%",
              zIndex: 2,
            }}
          />
          <p
            style={{
              width: "200px",
              position: "absolute",
              color: "white",
              zIndex: "1",
              top: "9%",
              left: "5%",
              textTransform: "uppercase",
              right: "20%",
            }}
          >
            {person.position}
          </p>
          <h5
            style={{
              position: "absolute",
              color: "rgb(230, 227, 227) ",
              zIndex: "3",
              top: "80%",
              left: "7%",
              textTransform: "uppercase",
            }}
          >
            {" "}
            {person.name.split(" ", 1)}{" "}
          </h5>
          <h5
            style={{
              position: "absolute",
              color: "rgb(230, 227, 227) ",
              zIndex: "3",
              top: "85%",
              left: "7%",
              textTransform: "uppercase",
            }}
          >
            {" "}
            {person.name.slice(person.name.lastIndexOf(" ") + 1)}{" "}
          </h5>
        </div>
      );
    }

    if (index === 2) {
      return (
        <div>
          <div
            className="col s5"
            style={{ height: "420px", position: "relative" }}
          >
            <div
              className="checker"
              style={{
                position: "absolute",
                top: "18%",
                height: "400px",
                width: "48%",
                left: "15%",
              }}
            >
              <div
                className="overlay"
                style={{ position: "absolute", top: "0", zIndex: "2" }}
              >
                <p
                  style={{
                    position: "absolute",
                    margin: "0",
                    overflowY: "scroll",
                    padding: "14%",
                    height: "275px",
                    color: "white",
                    zIndex: "50",
                    "font-weight": "bold",
                  }}
                  className="opacityFix"
                >
                  {" "}
                  {person.description}{" "}
                </p>
                {staffLinkedIn}
              </div>
            </div>
            <div
              style={{
                backgroundImage: `linear-gradient(
	              to bottom,
	              rgba(0, 0, 0, .5),
	              rgba(0, 0, 0, .2),
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
                ), url(${person.picture})`,
                backgroundSize: "cover",
                position: "absolute",
                top: "18%",
                height: "400px",
                width: "48%",
                left: "15%",
              }}
            />
            <div
              style={{
                width: "48%",
                backgroundImage:
                  "linear-gradient(to left, #01aed6, rgba(0, 0, 0, 0))",
                height: "7px",
                position: "absolute",
                top: "18%",
                left: "15%",
                zIndex: 2,
              }}
            />
            <p
              style={{
                width: "200px",
                position: "absolute",
                color: "white",
                zIndex: "1",
                top: "19%",
                left: "22%",
                right: "37%",
                textTransform: "uppercase",
              }}
            >
              {person.position}
            </p>
            <h5
              style={{
                position: "absolute",
                color: "rgb(230, 227, 227) ",
                zIndex: "4",
                top: "89%",
                left: "22%",
                textTransform: "uppercase",
              }}
            >
              {" "}
              {person.name.split(" ", 1)}{" "}
            </h5>
            <h5
              style={{
                position: "absolute",
                color: "rgb(230, 227, 227) ",
                zIndex: "4",
                top: "94%",
                left: "22%",
                textTransform: "uppercase",
              }}
            >
              {" "}
              {person.name.slice(person.name.lastIndexOf(" ") + 1)}{" "}
            </h5>
          </div>
        </div>
      );
    }

    return null;
  });

  return (
    <>
      <div style={{ paddingBottom: "50px" }}>
        <span className="desktopYes">
          <div className="row">{staff}</div>
        </span>
        <span className="ipadYes">
          <TeamMemberIpad />
        </span>
        <span className="inBetweenIpadAndPhone ">
          <TeamMemberBetween />
        </span>
        <span className="phoneYes">
          <TeamMemberMobile />
        </span>
      </div>
    </>
  );
};

export default connect(null, actions)(Team);
