import { combineReducers } from "redux";
import ContactReducer from "./contactReducer";
import MediaReducer from "./mediaReducer.js";
import LogosReducer from "./logoReducer";

const rootReducer = combineReducers({
  contact: ContactReducer,
  media: MediaReducer,
  logos: LogosReducer,
});

export default rootReducer;
