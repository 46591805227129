import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Dots from "../images/process-dots.webp";

class OurStory extends React.Component {
  render() {
    return (
      <div
        className="row padding-top footer center-items"
        style={{
          position: "relative",
          height: "fit-content",
          paddingTop: "100px",
          paddingBottom: "100px",
        }}
      >
        <div className="col s11 dot-col">
          <div className="hide-on-med-and-down" style={{ paddingLeft: "10%" }}>
            <hr />
            <p className="gray tab"> OUR STORY</p>
          </div>
        </div>
        <div
          className="col s12 responsive-flex"
          style={{ display: "flex", alignContent: "center" }}
        >
          <div
            className="col xl6 m12 s12"
            style={{
              marginTop: "40px",
              display: "flex",
              justifyContent: "center",
              padding: "30px",
            }}
          >
            <div>
              <h6>
                <strong>How VISIMO Solves Problems:</strong>
              </h6>
              <ul style={{ listStyleType: "disc" }}>
                <li
                  style={{
                    display: "list-item",
                    listStyleType: "disc",
                    listStylePosition: "inside",
                  }}
                >
                  Web application design & agile software development
                </li>
                <li
                  style={{
                    display: "list-item",
                    listStyleType: "disc",
                    listStylePosition: "inside",
                  }}
                >
                  Custom AI & ML algorithms & models 
                </li>
                <li
                  style={{
                    display: "list-item",
                    listStyleType: "disc",
                    listStylePosition: "inside",
                  }}
                >
                  Intuitive, user-friendly data analytics, dashboards, and
                  reports
                </li>
              </ul>
              <h6>
                <strong>How Is VISIMO Different?</strong>​
              </h6>
              <ul>
                <li
                  style={{
                    display: "list-item",
                    listStyleType: "disc",
                    listStylePosition: "inside",
                  }}
                >
                  We build trusted technology partnerships with subject matter
                  experts​
                </li>
                <li
                  style={{
                    display: "list-item",
                    listStyleType: "disc",
                    listStylePosition: "inside",
                  }}
                >
                  We thrive on a questioning, problem solving culture​
                </li>
              </ul>
              <h6>
                <strong>What VISIMO Values:</strong>​
              </h6>
              <ul>
                <li
                  style={{
                    display: "list-item",
                    listStyleType: "disc",
                    listStylePosition: "inside",
                  }}
                >
                  Human-centered analytics & evidence-based outcomes​
                </li>
                <li
                  style={{
                    display: "list-item",
                    listStyleType: "disc",
                    listStylePosition: "inside",
                  }}
                >
                  Constant, disruptive innovation
                </li>
                <li
                  style={{
                    display: "list-item",
                    listStyleType: "disc",
                    listStylePosition: "inside",
                  }}
                >
                  Mentorship & comradery​
                </li>
                <li
                  style={{
                    display: "list-item",
                    listStyleType: "disc",
                    listStylePosition: "inside",
                  }}
                >
                  Speaking the truth & being adaptable
                </li>
                <li
                  style={{
                    display: "list-item",
                    listStyleType: "disc",
                    listStylePosition: "inside",
                  }}
                >
                  Investing in people, solutions, and research
                </li>
              </ul>
            </div>
          </div>
          <div
            className="col xl6 m12 s12"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="iframe-container">
              <iframe
                title="vimeo-player"
                src="https://player.vimeo.com/video/575499833"
                className="responsive-iframe"
                frameborder="0"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              ></iframe>
            </div>
          </div>
        </div>
        <img
          src={Dots}
          className="hide-on-med-and-down"
          style={{
            position: "absolute",
            zIndex: "20",
            height: "100px",
            bottom: "50px",
            left: "-10px",
          }}
          alt="Dots"
        />
      </div>
    );
  }
}

export default connect(null, actions)(OurStory);
