import React from "react";

export default function Diversity() {
  return (
    <div>
      <h6>VISIMO Values More Than Data. We Value People. </h6>
      <p>
        {" "}
        Diverse data is the backbone of human-centered analytics. The VISIMO
        team brings their unique views and experiences together to build
        innovative tools and solve complex problems.{" "}
      </p>
      <p>
        VISIMO recruits and rewards smart, responsible, and creative people,
        regardless of education, opinions, culture, ethnicity, race, sex, gender
        identity and expression, nation of origin, age, languages spoken,
        veteran's status, color, religion, disability, sexual orientation, and
        beliefs.{" "}
      </p>

      <p>
        VISIMO welcomes the significant contributions our employees bring
        through their unique backgrounds, and actively seek projects that match
        the diverse passions of our team.{" "}
      </p>
    </div>
  );
}
