import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";

const OurTeam = (props) => {
  return (
    <div className="row  padding-top">
      <div className="offset-l6 col l5 m12 bold right-align">
        <h3>
          Meet Our Team Of Skilled{" "}
          <span className="outline">Professionals</span>.
        </h3>
      </div>
      <div className="col 11 m1 vertical hide-on-med-and-down">
        <p className="gray rotate">
          <span>
            <hr />
          </span>{" "}
          OUR TEAM
        </p>
      </div>
    </div>
  );
};

export default connect(null, actions)(OurTeam);
