import React, { Component } from "react";
import NavBar from "./navBar/navBar";
import Footer from "../footer/footer";
import Blue from "../images/VISIMO_Site_Contact-09.webp";
import "./case-studies.css";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import Dots from "../images/left-dots.webp";
import Men from "../images/approach.webp";
import { apiUrl } from "../config";

class LandingPage extends Component {
  constructor(props) {
    super();
    this.state = {
      exact: [],
      match: props.match.params.slug,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    axios({
      method: "GET",
      url: `${apiUrl}/case_study/${this.state.match}`,
    }).then((res) => {
      this.setState({ exact: res.data });
    });
  }

  render() {
    return (
      <>
        <NavBar />

        {/* for non mobile view  overflow scroll*/}
        <span className="mobileNo">
          <div className="row" style={{ "padding-top": "150px" }}>
            <div
              className="col s2"
              style={{ position: "relative", height: "400px" }}
            >
              <img
                src={Blue}
                style={{
                  position: "absolute",
                  left: "0",
                  height: "100%",
                  width: "5%",
                }}
                alt="Blue"
              />
            </div>

            <div
              className="col s6"
              style={{
                position: "relative",
                height: "400px",
                margin: "0",
                padding: "0",
              }}
            >
              <h1 style={{ position: "absolute", left: "-10%" }}>
                {" "}
                {this.state.exact.title}{" "}
              </h1>
              <p
                style={{
                  top: "50%",
                  position: "absolute",
                  left: "-10%",
                  right: "15%",
                }}
              >
                {" "}
                {this.state.exact.summary}{" "}
              </p>
            </div>

            <div
              className="col s3 l2 black"
              style={{ position: "relative", height: "550px" }}
            >
              <p
                style={{
                  position: "absolute",
                  color: "grey",
                  top: " 5%",
                  left: "10%",
                }}
              >
                {" "}
                Year{" "}
              </p>
              <h5
                style={{
                  position: "absolute",
                  color: "white",
                  top: "10%",
                  left: "10%",
                }}
              >
                {" "}
                {this.state.exact.year}{" "}
              </h5>
              <p
                style={{
                  position: "absolute",
                  color: "grey",
                  top: " 20%",
                  left: "10%",
                }}
              >
                {" "}
                The Challenge{" "}
              </p>
              <p
                style={{
                  position: "absolute",
                  color: "white",
                  top: "24%",
                  left: "10%",
                  right: "10%",
                  overflow: "hidden",
                  bottom: "36%",
                }}
              >
                {" "}
                {this.state.exact.challenge}{" "}
              </p>
              <p
                style={{
                  position: "absolute",
                  color: "grey",
                  top: " 60%",
                  left: "10%",
                }}
              >
                {" "}
                The Solution{" "}
              </p>
              <p
                style={{
                  position: "absolute",
                  color: "white",
                  top: "64%",
                  left: "10%",
                  right: "10%",
                  overflow: "hidden",
                  bottom: "0%",
                }}
              >
                {" "}
                {this.state.exact.solution}{" "}
              </p>
            </div>

            <div
              className="col s1 l2"
              style={{ position: "relative", height: "400px" }}
            />

            <div
              className="col s12"
              style={{
                backgroundImage: `url(${this.state.exact.image})`,
                backgroundSize: "cover",
                position: "relative",
                height: "500px",
                margin: "0",
                padding: "0",
                marginTop: "-100px",
                zIndex: "-2",
              }}
            />

            <div
              className="col s12"
              style={{
                padding: "100px 200px 250px 200px",
                position: "relative",
              }}
            >
              {ReactHtmlParser(this.state.exact.body)}
              <img
                src={Dots}
                style={{
                  position: "absolute",
                  left: "0px",
                  top: "0px",
                  height: "500px",
                }}
                alt="Dots"
              />
            </div>

            <div
              className="col s12 black"
              style={{
                position: "relative",
                height: "500px",
                "border-bottom": "solid 1px grey",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "90%",
                  height: "400px",
                  backgroundImage: `url(${Men})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  top: "-40%",
                  left: "0px",
                }}
              />
              <p
                style={{
                  position: "absolute",
                  color: "grey",
                  top: " 65%",
                  left: "15%",
                }}
              >
                {" "}
                Next Steps{" "}
              </p>
              <p
                style={{
                  position: "absolute",
                  color: "white",
                  top: " 65%",
                  left: "30%",
                  right: "20%",
                }}
              >
                {" "}
                {this.state.exact.next_steps}{" "}
              </p>
            </div>
            <div
              className="col s12 black"
              style={{
                position: "relative",
                height: "250px",
                "border-bottom": "solid 1px #01AED6",
              }}
            >
              <p
                style={{
                  position: "absolute",
                  color: "grey",
                  top: " 40%",
                  left: "15%",
                }}
              >
                {" "}
                The Benefits{" "}
              </p>
              <p
                style={{
                  position: "absolute",
                  color: "white",
                  top: " 40%",
                  left: "30%",
                  right: "20%",
                }}
              >
                {" "}
                {this.state.exact.benefits}{" "}
              </p>
            </div>
          </div>
        </span>
        {/* end non mobile view */}

        {/* for mobile view */}
        <span className="mobileYes">
          <div className="row" style={{ "padding-top": "150px" }}>
            <div
              className="col s2"
              style={{ position: "relative", height: "400px" }}
            >
              <img
                src={Blue}
                style={{
                  position: "absolute",
                  left: "0",
                  height: "100%",
                  width: "5%",
                }}
                alt="Blue"
              />
            </div>

            <div
              className="col s10"
              style={{
                position: "relative",
                height: "400px",
                margin: "0",
                padding: "0",
              }}
            >
              <h1 style={{ position: "absolute", left: "-10%" }}>
                {" "}
                {this.state.exact.title}{" "}
              </h1>
              <p
                style={{
                  top: "50%",
                  position: "absolute",
                  left: "-10%",
                  right: "15%",
                }}
              >
                {" "}
                {this.state.exact.summary}{" "}
              </p>
            </div>

            <div
              className="col s12 black"
              style={{ position: "relative", height: "550px" }}
            >
              <p
                style={{
                  position: "absolute",
                  color: "grey",
                  top: " 5%",
                  left: "15%",
                }}
              >
                {" "}
                Year{" "}
              </p>
              <h5
                style={{
                  position: "absolute",
                  color: "white",
                  top: "10%",
                  left: "15%",
                }}
              >
                {" "}
                {this.state.exact.year}{" "}
              </h5>
              <p
                style={{
                  position: "absolute",
                  color: "grey",
                  top: " 20%",
                  left: "15%",
                }}
              >
                {" "}
                The Challenge{" "}
              </p>
              <p
                style={{
                  position: "absolute",
                  color: "white",
                  top: "25%",
                  left: "15%",
                  right: "20%",
                }}
              >
                {" "}
                {this.state.exact.challenge}{" "}
              </p>
              <p
                style={{
                  position: "absolute",
                  color: "grey",
                  top: " 55%",
                  left: "15%",
                }}
              >
                {" "}
                The Solution{" "}
              </p>
              <p
                style={{
                  position: "absolute",
                  color: "white",
                  top: "60%",
                  left: "15%",
                  right: "20%",
                }}
              >
                {" "}
                {this.state.exact.solution}{" "}
              </p>
            </div>

            <div
              className="col s12"
              style={{
                backgroundImage: `url(${this.state.exact.image})`,
                backgroundSize: "cover",
                position: "relative",
                height: "500px",
                margin: "0",
                padding: "0",
                marginTop: "-100px",
                zIndex: "-2",
              }}
            ></div>

            <div className="col s12" style={{ padding: "50px 50px" }}>
              {ReactHtmlParser(this.state.exact.body)}
            </div>

            <div
              className="col s12 black"
              style={{
                position: "relative",
                height: "250px",
                "border-bottom": "solid 1px grey",
              }}
            >
              <p
                style={{
                  position: "absolute",
                  color: "grey",
                  top: " 40%",
                  left: "5%",
                }}
              >
                {" "}
                Next Steps{" "}
              </p>
              <p
                style={{
                  position: "absolute",
                  color: "white",
                  top: " 40%",
                  left: "25%",
                  right: "10%",
                }}
              >
                {" "}
                {this.state.exact.next_steps}{" "}
              </p>
            </div>
            <div
              className="col s12 black"
              style={{
                position: "relative",
                height: "250px",
                "border-bottom": "solid 1px #01AED6",
              }}
            >
              <p
                style={{
                  position: "absolute",
                  color: "grey",
                  top: " 40%",
                  left: "5%",
                }}
              >
                {" "}
                The Benefits{" "}
              </p>
              <p
                style={{
                  position: "absolute",
                  color: "white",
                  top: " 40%",
                  left: "25%",
                  right: "10%",
                }}
              >
                {" "}
                {this.state.exact.benefits}{" "}
              </p>
            </div>
          </div>
        </span>
        {/* end mobile view */}

        <Footer />
      </>
    );
  }
}

export default LandingPage;
