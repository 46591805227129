import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./../actions";
import "./case-studies.css";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCase: 0,
    };
    this.prevFunction = this.prevFunction.bind(this);
    this.nextFunction = this.nextFunction.bind(this);
  }

  prevFunction() {
    if (this.state.currentCase === 0) {
      this.setState({
        currentCase: this.props.studies.length - 1,
      });
    } else {
      this.setState({
        currentCase: this.state.currentCase - 1,
      });
    }
  }

  nextFunction() {
    this.setState({
      currentCase: (this.state.currentCase + 1) % this.props.studies.length,
    });
  }

  render() {
    if (this.props.studies[this.state.currentCase] === null) {
      return <p> loading, please wait ...</p>;
    } else {
      return (
        <div className="row">
          <div
            className="col s12"
            style={{
              height: "600px",
              position: "relative",
              "padding-left": "100px",
            }}
          >
            <Link
              to={`/case-studies/${
                this.props.studies[this.state.currentCase].abbrev_slug
              }`}
              style={{ position: "absolute", top: "90%", left: "11%" }}
            >
              <button
                className="btn waves-effect waves-red"
                type="submit"
                name="action"
              >
                FULL PROJECT
                <i className="material-icons right">arrow_forward</i>
              </button>
            </Link>
            <h1
              style={{
                position: "absolute",
                color: "white",
                top: "35%",
                left: "11%",
                right: "40%",
              }}
            >
              {this.props.studies[this.state.currentCase].title}
              <span
                style={{
                  color: "#01AED6 ",
                  fontSize: "30px",
                  "": "none",
                  "padding-top": "10px",
                  marginLeft: "10px",
                }}
              >
                {" "}
                -
              </span>
              <span style={{ fontSize: "30px" }}>
                {this.props.studies[this.state.currentCase].year}
              </span>
            </h1>
            <p
              style={{
                position: "absolute",
                color: "white",
                top: "65%",
                left: "11%",
                right: "10%",
                fontSize: "20px",
              }}
            >
              {this.props.studies[this.state.currentCase].summary}
            </p>
            <div
              style={{
                backgroundImage: `url(${
                  this.props.studies[this.state.currentCase].image
                })`,
                backgroundSize: "cover",
                position: "absolute",
                padding: "0",
                width: "100%",
                height: "600px",
                zIndex: "-25",
                top: "0",
                left: "0",
              }}
            />

            {/*bottom right buttons */}
            <p
              style={{
                color: "white",
                position: "absolute",
                bottom: "10%",
                right: "3%",
                fontSize: "20px",
              }}
            >
              {this.state.currentCase + 1} / {this.props.studies.length}
            </p>
            <button
              className="btn waves-effect waves-red"
              style={{
                height: "10%",
                width: "4%",
                position: "absolute",
                bottom: "0%",
                right: "4%",
              }}
              onClick={this.prevFunction}
            >
              <i
                className="material-icons left"
                style={{ transform: "rotate(180deg)" }}
              >
                arrow_forward
              </i>
            </button>
            <button
              className="btn waves-effect waves-red"
              style={{
                height: "10%",
                width: "4%",
                position: "absolute",
                bottom: "0%",
                right: "0%",
              }}
              onClick={this.nextFunction}
            >
              <i className="material-icons right">arrow_forward</i>
            </button>

            <div
              className="darkGradient"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                left: "0",
                top: "0",
                opacity: ".4",
                zIndex: "-20",
              }}
            ></div>
          </div>
        </div>
      );
    }
  }
}

export default connect(null, actions)(Header);
