import React from "react";
import { connect } from "react-redux";
import * as actions from "./../../actions";
import "./header.css";
import Wave from "./../../images/VISIMO_Digital_Wave_White.webp";

const Header = () => {
  return (
    <div className="header black" style={{ zIndex: "-1" }}>
      <div className="container animate__animated animate__fadeIn">
        <div className="row large-padding">
          <div
            className="col s12"
            style={{ height: "100px", position: "relative" }}
          >
            <span style={{ position: "absolute" }}>
              <h1 style={{ color: "white", margin: "0", padding: "0" }}>
                VISIMO News & Insights<span className="period">.</span>
              </h1>
              <p
                style={{
                  color: "white",
                  margin: "0",
                  padding: "0",
                  marginTop: "25px",
                }}
              >
                Read the latest news and thoughts from experts on topics like
                data analytics, machine learning, and artificial intelligence.
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, actions)(Header);
