import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import "./middle.css";

const MissionStatement = (props) => {
  return (
    <div className="large-margin row">
      <div className="col l2 dot-col">
        <br />
        <br />
        <hr className="mission-line" />
      </div>
      <h1 className="col l9 bold mobileFont">
        We're more than a data company. We're a collection of hungry, innovative
        problem solvers driven to develop custom technology and software
        solutions using the latest in analytics and artificial intelligence
        <span className="period">.</span>
      </h1>
    </div>
  );
};

export default connect(null, actions)(MissionStatement);
