import { CONTACT_SUCCESS, CONTACT_ERROR } from "../actions/types";

export default function (state = null, action) {
  switch (action.type) {
    case CONTACT_SUCCESS:
      return "SUCCESS";
    case CONTACT_ERROR:
      return "ERROR";
    default:
      return state;
  }
}
