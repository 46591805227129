import React, { Component } from "react";
import axios from "axios";
import "./who-we-are.css";
import { apiUrl } from "../config";

class TeamMemberMobile extends Component {
  constructor() {
    super();
    this.state = {
      persons: [],
      currentPerson: 0,
      initial: 0,
    };
    this.prevFunction = this.prevFunction.bind(this);
    this.nextFunction = this.nextFunction.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    axios({
      method: "GET",
      url: `${apiUrl}/employees/`,
    }).then((res) => {
      this.setState({ persons: res.data });
    });
  }

  prevFunction() {
    if (this.state.currentPerson !== this.state.initial) {
      this.setState({
        currentPerson: this.state.currentPerson - 1,
      });
    } else {
      this.setState({
        currentPerson: this.state.persons.length - 1,
      });
    }
  }

  nextFunction() {
    this.setState({
      currentPerson:
        Math.abs(this.state.currentPerson + 1) % this.state.persons.length,
    });
  }

  render() {
    if (this.state.persons[this.state.currentPerson] == null) {
      return <p> loading, please wait ...</p>;
    } else {
      return (
        <>
          <div className="row">
            <div
              className="col s12 white"
              style={{ position: "relative", height: "400px" }}
            >
              <div
                className="checker"
                style={{
                  position: "absolute",
                  height: "300px",
                  width: "70%",
                  left: "20%",
                  top: "10%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    backgroundImage:
                      "linear-gradient(to left, #01aed6, rgba(0, 0, 0, 0))",
                    height: "7px",
                    position: "absolute",
                    left: "0%",
                    top: "0px",
                    zIndex: 2,
                  }}
                />
                <div
                  style={{
                    backgroundImage: `linear-gradient(
	              to bottom,
	              rgba(0, 0, 0, .5),
	              rgba(0, 0, 0, .2),
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
                ),url(${this.state.persons[this.state.currentPerson].picture})`,
                    backgroundSize: "cover",
                    height: " 100%",
                    width: "100%",
                  }}
                />
                <div
                  className="overlay"
                  style={{ position: "absolute", top: "0", zIndex: "2" }}
                >
                  <p
                    style={{
                      position: "absolute",
                      color: "white",
                      margin: "0",
                      padding: "14%",
                      height: "225px",
                      overflowY: "scroll",
                      fontSize: "14px",
                      zIndex: "50",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                    className="opacityFix"
                  >
                    {this.state.persons[this.state.currentPerson].description}
                  </p>
                </div>
              </div>

              <p
                style={{
                  position: "absolute",
                  color: "white",
                  zIndex: "1",
                  top: "14%",
                  left: "27%",
                  right: "27%",
                  textTransform: "uppercase",
                }}
              >
                {this.state.persons[this.state.currentPerson].position}
              </p>
              <h5
                style={{
                  position: "absolute",
                  color: "rgb(230, 227, 227)",
                  zIndex: "4",
                  top: "68%",
                  left: "27%",
                  textTransform: "uppercase",
                }}
              >
                {" "}
                {this.state.persons[this.state.currentPerson].name.split(
                  " ",
                  1
                )}{" "}
              </h5>
              <h5
                style={{
                  position: "absolute",
                  color: "rgb(230, 227, 227)",
                  zIndex: "4",
                  top: "74%",
                  left: "27%",
                  textTransform: "uppercase",
                }}
              >
                {" "}
                {this.state.persons[this.state.currentPerson].name.slice(
                  this.state.persons[this.state.currentPerson].name.lastIndexOf(
                    " "
                  ) + 1
                )}{" "}
              </h5>

              <button
                className="btn waves-effect waves-red"
                style={{ position: "absolute", bottom: "-5%", left: "20%" }}
                onClick={this.prevFunction}
              >
                <i
                  className="material-icons "
                  style={{ transform: "rotate(180deg)" }}
                >
                  arrow_forward
                </i>
              </button>
              <button
                className="btn waves-effect waves-red"
                style={{ position: "absolute", bottom: "-5%", left: "35%" }}
                onClick={this.nextFunction}
              >
                <i className="material-icons ">arrow_forward</i>
              </button>
            </div>
          </div>
        </>
      );
    }
  }
}

export default TeamMemberMobile;
