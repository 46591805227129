import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../actions";
import "./header.css";

const Header = (props) => {
  const history = useHistory();
  return (
    <div className="header">
      <div className="container animate__animated animate__fadeIn">
        <div className="row large-padding">
          <div className="col s12 l12">
            <h1>
              We help people envision what could be, and partner to create it
              <span className="period">.</span>
            </h1>
            <p style={{ fontSize: "20px" }}>
              We are: stewards of people, drivers of change, and creators of
              disruptive products.
            </p>
            <div style={{ height: "70px", display: "flex" }}>
              <button
                className="btn waves-effect waves-red"
                type="submit"
                name="action"
                style={{ marginLeft: "-1px" }}
                onClick={() => history.push("/who-we-are")}
              >
                LEARN MORE
                <i className="material-icons right">arrow_forward</i>
              </button>
              <button
                className="btn waves-effect waves-red"
                type="submit"
                name="action"
                onClick={() => history.push("/contact")}
              >
                GET IN TOUCH
                <i className="material-icons right">arrow_forward</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, actions)(Header);
