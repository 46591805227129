import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Wave from "../images/flipWave.webp";
import { Link } from "react-router-dom";

const GetInTouch = (props) => {
  return (
    <div className="row ">
      <div
        className="col s12 white"
        style={{ height: "300px", position: "relative" }}
      >
        <center style={{ marginTop: "100px" }}>
          <p style={{ color: "grey", fontWeight: "bold" }}>
            {" "}
            START A CONVERSATION
          </p>
          <Link to="/contact/">
            <h3 style={{ color: "black" }}>
              Get In <span className="outline">Touch</span>.
            </h3>
          </Link>
        </center>
        <span className="mobileNo">
          <img
            src={Wave}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
              height: "800px",
            }}
            alt="Wave"
          />
        </span>
      </div>
    </div>
  );
};

export default connect(null, actions)(GetInTouch);
