import React, { Component } from "react";
import NavBar from "../navBar/navBar";
import MissionStatement from "./missionStatement";
import Footer from "../footer/footer";
import OurStory from "./ourStory";
import OurTeam from "./meetOurTeam.js";
import Team from "./teamMember.js";
import axios from "axios";
import Blue from "../images/VISIMO_Site_Services-30-Rotated.webp";
import GetInTouch from "./getInTouch.js";
import { apiUrl } from "../config";

class WhoWeAre extends Component {
  state = {
    persons: [],
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    axios({
      method: "GET",
      url: `${apiUrl}/employees/`,
    }).then((res) => {
      this.setState({ persons: res.data });
    });
  }

  render() {
    return (
      <>
        <div className="white">
          <div className="header-image-who-we-are">
            <NavBar />
            <MissionStatement />
            <img
              src={Blue}
              style={{
                position: "absolute",
                bottom: "-40px",
                left: "0",
                height: "80px",
                width: "18%",
                zIndex: "40",
              }}
              alt="Blue"
            />
          </div>

          <OurStory />

          <div className="left-dots-wrapper">
            <OurTeam />
          </div>
          <Team teamData={this.state.persons} />
          <GetInTouch />
          <Footer />
        </div>
      </>
    );
  }
}

export default WhoWeAre;
