import React, { Component } from "react";
import NavBar from "../navBar/navBar";
import Footer from "../footer/footer";
import Header from "./header";
import "./case-studies.css";
import axios from "axios";
import { apiUrl } from "../config";

class CaseStudies extends Component {
  constructor(props) {
    super();
    this.state = {
      studies: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    axios({
      method: "GET",
      url: `${apiUrl}/case_study/`,
    }).then((res) => {
      this.setState({ studies: res.data });
    });
  }

  render() {
    return (
      <>
        <NavBar />
        <Header studies={this.state.studies} />
        <Footer />
      </>
    );
  }
}

export default CaseStudies;
