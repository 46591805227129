import React from "react";
import "./footer.css";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Link } from "react-router-dom";
import pdf from "../images/PrivatePolicy.pdf";

const Footer = () => {
  const privatePolicyClick = () => {
    window.open(pdf);
  };

  return (
    <div className="footer">
      <div className="row">
        <div className="col l5 medium extra-padding top-border left-border ">
          <p className="gray">PHONE</p>
          <h4>
            <a href="tel:4126996900" style={{ color: "white" }}>
              412.699.6900
            </a>
          </h4>
          <p className="gray">EMAIL</p>
          <h4>
            <a style={{ color: "white" }} href="mailto:info@visimo.ai">
              INFO@VISIMO.AI
            </a>
          </h4>
          <p className="gray">FOLLOW US</p>
          <h4>
            <a
              style={{ color: "#FAFAFF" }}
              href="https://www.facebook.com/visimoco"
            >
              FB
            </a>{" "}
            <a
              style={{ color: "#FAFAFF" }}
              href="https://www.linkedin.com/company/visimo-llc/"
            >
              LI
            </a>{" "}
            <a
              style={{ color: "#FAFAFF" }}
              href="https://twitter.com/VISIMO_AI"
            >
              TW
            </a>
          </h4>
        </div>
        <div className="col l7 ">
          <div className="row  top-border"></div>
          <div className="row  " style={{ padding: "50px" }}>
            <div className="col ">
              <p className="gray medium">NAVIGATION</p>
              <ul>
                <li>
                  <Link to="/who-we-are" style={{ color: "#FAFAFF" }}>
                    Who We Are
                  </Link>
                </li>
                <li>
                  <Link to="/media" style={{ color: "#FAFAFF" }}>
                    Media
                  </Link>
                </li>
                <li>
                  <Link to="/contact" style={{ color: "#FAFAFF" }}>
                    Contact
                  </Link>
                </li>
                <li>
                  <Link to="/careers" style={{ color: "#FAFAFF" }}>
                    Careers
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row top-border">
        <p>
          <span className="gray">© {new Date().getFullYear()} VISIMO | </span>
          <a
            style={{ color: "#FAFAFF" }}
            href="https://www.google.com/maps/place/520+E+Main+St+%23200,+Carnegie,+PA+15106/@40.4109844,-80.0827675,17z/data=!3m1!4b1!4m5!3m4!1s0x8834f634b2c8b289:0xb20bb3a814cbb481!8m2!3d40.4109844!4d-80.0805788"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            520 E Main Street Suite 200, Carnegie, PA 15106{" "}
          </a>
          <span className="gray right-align">
            Terms Of Use{" "}
            <span onClick={privatePolicyClick} style={{ cursor: "pointer" }}>
              Privacy Policy
            </span>
          </span>
        </p>
      </div>
    </div>
  );
};

export default connect(null, actions)(Footer);
