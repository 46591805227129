import React from "react";
import Header from "./header/header";
import Middle from "./middle/middle";
import Overlay from "./images/VISIMO_Overlay.webp";
import NavBar from "./navBar/navBar";

import Footer from "./footer/footer";

function App() {
  return (
    <>
      <div className="header-image" style={{ position: "relative" }}>
        <NavBar />
        <Header />
        <img
          src={Overlay}
          className="visimo-overlay hide-on-small-only"
          alt="Overlay"
        />
      </div>
      <Middle />
      <Footer />
    </>
  );
}

export default App;
