import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../actions";
import Logo from "../../images/VISIMO_Logo_Two_RGB_Black.webp";
import "./navBar.css";
import M from "materialize-css";

class NavBar extends React.Component {
  // get a reference to the element after the component has mounted
  componentDidMount() {
    M.Sidenav.init(this.sidenav);
  }

  render() {
    return (
      <>
        <nav
          className="transparentBG animate__animated animate__fadeIn"
          style={{ position: "absolute", zIndex: "1" }}
        >
          <div className="nav-wrapper">
            <Link to="/">
              {" "}
              <img
                src={Logo}
                className="brand-logo left"
                alt="VISIMO logo"
              />{" "}
            </Link>
            <Link
              href="#"
              data-target="mobile-demo"
              className="sidenav-trigger right"
            >
              <i className="material-icons" style={{ color: "black" }}>
                menu
              </i>
            </Link>
            <ul className="right hide-on-med-and-down">
              <li>
                <Link to="/">
                  <span style={{ color: "black" }}>Home</span>
                </Link>
              </li>
              <li>
                <Link to="/who-we-are">
                  <span style={{ color: "black" }}>Who We Are</span>
                </Link>
              </li>
              {/* <li><Link to="/specialties"><span style={{'color' : 'black'}}>Our Specialties</span></Link></li>*/}
              <li>
                <Link to="/media">
                  <span style={{ color: "black" }}>Media</span>
                </Link>
              </li>
              <li>
                <Link to="/contact">
                  <span style={{ color: "black" }}>Contact</span>
                </Link>
              </li>
              <li>
                <Link to="/careers">
                  <span style={{ color: "black" }}>Contact</span>
                </Link>
              </li>
            </ul>
          </div>
        </nav>

        <ul className="sidenav" id="mobile-demo">
          <li>
            <Link to="/">
              <img
                src={Logo}
                className="brand-logo nav-sidebar-image"
                alt="VISIMO logo"
              />
            </Link>
          </li>
          <li style={{ margin: "0", padding: "0", color: "#FAFAFF" }}>
            <Link to="/who-we-are">
              <h4>Who We Are</h4>
            </Link>
          </li>
          <li style={{ margin: "0", padding: "0", color: "#FAFAFF" }}>
            <Link to="/media">
              <h4 style={{ margin: "0", padding: "0" }}>Media</h4>
            </Link>
          </li>
          <li style={{ margin: "0", padding: "0", color: "#FAFAFF" }}>
            <Link to="/contact">
              <h4 style={{ margin: "0", padding: "0" }}>Contact</h4>
            </Link>
          </li>
          <li style={{ margin: "0", padding: "0" }}>
            <Link style={{ color: "#FAFAFF" }} to="/careers">
              <h4 style={{ margin: "0", padding: "0" }}>Careers</h4>
            </Link>
          </li>
        </ul>
      </>
    );
  }
}

export default connect(null, actions)(NavBar);
