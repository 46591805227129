import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Aos from "aos";
import "aos/dist/aos.css";
import "./middle.css";
import Service1 from "../images/service1.webp";
import Service2 from "../images/service2.webp";
import Service3 from "../images/service3.webp";
import Service4 from "../images/huckesteinService.webp";
import Service5 from "../images/chairsService.webp";
import Service6 from "../images/fixing1.webp";

class Services extends Component {
  componentDidMount() {
    Aos.init({});
  }

  render() {
    return (
      <div className="container">
        <div className="row center-align large-margin">
          {/* left */}
          <div
            data-aos="slide-up"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="400"
            data-aos-offset="100"
            className="col l4 hoverCheck"
          >
            <div
              className="sidePicLeft"
              style={{
                backgroundImage: `url(${Service1})`,
                backgroundSize: "cover",
                height: "450px",
                top: "21px",
                left: "2%",
              }}
            />

            <div style={{ position: "relative", height: "475px" }}></div>
            <p className="medium tab" style={{ position: "relative" }}>
              A.I./Machine Learning Solutions
            </p>
            <p>
              Your business can change daily – your decision tools should too.
              Our custom artificial intelligence and machine learning solutions
              adapt to your business and keep the complex programming behind the
              scenes.
            </p>

            <div
              className="addOverlay2"
              style={{
                position: "absolute",
                top: "21px",
                zIndex: "3",
                height: "451px",
                width: "95%",
                left: "2%",
              }}
            >
              <p
                style={{
                  position: "absolute",
                  color: "white",
                  marginTop: "75px",
                  marginLeft: "14%",
                  marginRight: "20px",
                  fontSize: "18px",
                  zIndex: "50",
                  fontWeight: "bold",
                }}
                className="opacityFix"
              ></p>
            </div>
          </div>

          {/* middle */}
          <div
            data-aos="slide-up"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="200"
            data-aos-offset="100"
            className="col l4 hoverCheck"
          >
            <div
              style={{
                backgroundImage: `url(${Service2})`,
                backgroundSize: "cover",
              }}
              className="maxPic"
            />
            <hr />
            <div style={{ position: "relative", height: "430px" }}></div>
            <p className="medium tab" style={{ position: "relative" }}>
              Custom Web Applications{" "}
            </p>
            <p>
              Whether you need an internal or external solution, we design
              systems that solve your most critical needs. Our customizable web
              applications address a variety of problems across a broad range of
              industries.
            </p>

            <div
              className="addOverlay"
              style={{
                position: "absolute",
                top: "0",
                zIndex: "3",
                height: "450px",
                width: "95%",
                left: "2.5%",
              }}
            >
              <p
                style={{
                  position: "absolute",
                  color: "white",
                  marginTop: "75px",
                  marginLeft: "14%",
                  marginRight: "20px",
                  fontSize: "18px",
                  zIndex: "50",
                  fontWeight: "bold",
                }}
                className="opacityFix"
              ></p>
            </div>
          </div>

          {/* right */}
          <div
            data-aos="slide-up"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="400"
            data-aos-offset="100"
            className="col l4 hoverCheck"
          >
            <div
              className="sidePicLeft"
              style={{
                backgroundImage: `url(${Service3})`,
                backgroundSize: "cover",
                height: "450px",
                top: "21px",
                left: "2%",
              }}
            />

            <div style={{ position: "relative", height: "475px" }}></div>
            <p className="medium tab" style={{ position: "relative" }}>
              Business Intelligence Dashboards
            </p>
            <p>
              {" "}
              Our business dashboards are a lifeline to your company. They
              understand what happened, predict what will happen next, and
              provide actionable insights
            </p>

            <div
              className="addOverlay2"
              style={{
                position: "absolute",
                top: "21px",
                zIndex: "3",
                height: "451px",
                width: "95%",
                left: "2%",
              }}
            >
              <p
                style={{
                  position: "absolute",
                  color: "white",
                  marginTop: "75px",
                  marginLeft: "14%",
                  marginRight: "20px",
                  fontSize: "18px",
                  zIndex: "50",
                  fontWeight: "bold",
                }}
                className="opacityFix"
              ></p>
            </div>
          </div>
        </div>

        <div className="row center-align">
          {/* left */}
          <div
            data-aos="slide-up"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="400"
            data-aos-offset="100"
            className="col l4 hoverCheck"
          >
            <div
              className="sidePicLeft"
              style={{
                backgroundImage: `url(${Service4})`,
                backgroundSize: "cover",
                height: "450px",
                top: "21px",
                left: "2%",
              }}
            />

            <div style={{ position: "relative", height: "475px" }}></div>
            <p className="medium tab" style={{ position: "relative" }}>
              Predictive Analytics
            </p>
            <p>
              Our experienced professionals employ predictive analysis to help
              clients across multiple industries, including government & private
              sector, healthcare, retail, energy oil & gas, and more.
            </p>

            <div
              className="addOverlay2"
              style={{
                position: "absolute",
                top: "21px",
                zIndex: "3",
                height: "451px",
                width: "95%",
                left: "2%",
              }}
            >
              <p
                style={{
                  position: "absolute",
                  color: "white",
                  marginTop: "75px",
                  marginLeft: "14%",
                  marginRight: "20px",
                  fontSize: "18px",
                  zIndex: "50",
                  fontWeight: "bold",
                }}
                className="opacityFix"
              ></p>
            </div>
          </div>

          {/* middle */}
          <div
            data-aos="slide-up"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="200"
            data-aos-offset="100"
            className="col l4 hoverCheck"
          >
            <div
              style={{
                backgroundImage: `url(${Service5})`,
                backgroundSize: "cover",
              }}
              className="maxPic"
            />
            <hr />
            <div style={{ position: "relative", height: "430px" }}></div>
            <p className="medium tab" style={{ position: "relative" }}>
              Automation of Business Processes
            </p>
            <p>
              By automating specific business processes, VISIMO streamlines
              day-to-day operations of repeatable processes like H.R., payroll,
              contract management, and others error-free every time.
            </p>

            <div
              className="addOverlay"
              style={{
                position: "absolute",
                top: "0",
                zIndex: "3",
                height: "450px",
                width: "95%",
                left: "2.5%",
              }}
            >
              <p
                style={{
                  position: "absolute",
                  color: "white",
                  marginTop: "75px",
                  marginLeft: "14%",
                  marginRight: "20px",
                  fontSize: "18px",
                  zIndex: "50",
                  fontWeight: "bold",
                }}
                className="opacityFix"
              ></p>
            </div>
          </div>

          {/* right */}
          <div
            data-aos="slide-up"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="400"
            data-aos-offset="100"
            className="col l4 hoverCheck"
          >
            <div
              className="sidePicLeft"
              style={{
                backgroundImage: `url(${Service6})`,
                backgroundSize: "cover",
                height: "450px",
                top: "21px",
                left: "2%",
              }}
            />

            <div style={{ position: "relative", height: "475px" }}></div>
            <p className="medium tab" style={{ position: "relative" }}>
              Rapid Prototyping
            </p>
            <p>
              {" "}
              By getting feedback quickly, our team improves the final design,
              increases productivity, and reduces the need for changes later in
              the development process.
            </p>

            <div
              className="addOverlay2"
              style={{
                position: "absolute",
                top: "21px",
                zIndex: "3",
                height: "451px",
                width: "95%",
                left: "2%",
              }}
            >
              <p
                style={{
                  position: "absolute",
                  color: "white",
                  marginTop: "75px",
                  marginLeft: "14%",
                  marginRight: "20px",
                  fontSize: "18px",
                  zIndex: "50",
                  fontWeight: "bold",
                }}
                className="opacityFix"
              ></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, actions)(Services);
