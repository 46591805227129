import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "./../../actions";
import "./featured.css";
import Dots from "./../../images/process-dots.webp";
import Background from "../../images/VISIMO_Site_Media-25.webp";
import Article from "./articleComp.js";
import axios from "axios";
import { Parallax } from "react-parallax";
import { apiUrl } from "../../config";

class Featured extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
    };
  }

  componentDidMount() {
    axios({
      method: "GET",
      url: `${apiUrl}/media/`,
    }).then((res) => {
      this.setState({ articles: res.data });
    });
  }

  render() {
    return (
      <>
        <Parallax bgImage={Background} strength={1000}>
          <div className="row">
            <div
              className="col s12"
              style={{
                height: "600px",
                position: "relative",
                background: "rgb(0,0, 0, .4)",
              }}
            >
              <img
                src={Dots}
                style={{
                  position: "absolute",
                  right: "0",
                  top: "-60px",
                  height: "120px",
                  width: "4%",
                  zIndex: "500",
                }}
                alt="Dots"
              />
              <Article articleData={this.state.articles} />
            </div>
          </div>
        </Parallax>
      </>
    );
  }
}

export default connect(null, actions)(Featured);
