import { connect } from "react-redux";
import * as actions from "./../../actions";
import "./moreInfo.css";
import React, { Component } from "react";
import Article from "./article.js";
import axios from "axios";
import Footer from "./../../footer/footer.js";
import NextArticle from "./nextInfo.js";
import { apiUrl } from "../../config";

class MoreInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: [],
      match: props.match.params.slug,
      totalPages: 0,
      currentPage: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const fixedMatch = this.state.match.substring(0, 1);
    axios({
      method: "GET",
      url: `${apiUrl}/media/${fixedMatch}/`,
    }).then((res) => {
      this.setState({
        page: res.data,
        currentPage: fixedMatch,
      });
    });

    axios({
      method: "GET",
      url: `${apiUrl}/media/`,
    }).then((res) => {
      this.setState({ totalPages: res.data.length - 1 });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.slug !== this.state.page.id) {
      window.scrollTo(0, 0);
      const temp = this.props.match.params.slug.toString().substring(0, 1);

      axios({
        method: "GET",
        url: `${apiUrl}/media/${temp}/`,
      }).then((res) => {
        this.setState({
          page: res.data,
          currentPage: parseInt(temp),
        });
      });
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col s12">
            <Article articleList={this.state.page} />
          </div>

          <NextArticle
            currentPage={this.props.match.params.slug}
            listLength={this.state.totalPages}
          />

          <Footer />
        </div>
      </div>
    );
  }
}

export default connect(null, actions)(MoreInfo);
