import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import "./contactPage.css";
import NavBar from "./navBar/navBar.js";
import Footer from "../footer/footer.js";
import Form from "./inTouchForm.js";
import SidePic from "../images/VISIMO_Site_Contact-09.webp";
import Wave from "../images/wave-edited-vertical.webp";
import SmallForm from "./smallInTouchForm.js";

class Contact extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <NavBar />
        <div className="row" style={{ zIndex: "-1" }}>
          {/* for big screen on left panel */}
          <div className=" col s12 m4 editedPic">
            <span className="bigScreen">
              <center>
                <h1 className="onTop">Get In</h1>
                <h1 className="onTop">
                  <span className="white-outline">Touch</span>
                  <span className="period">. </span>
                </h1>
                <p className="onTop3">
                  {" "}
                  <span className="period2 onTop2">_ </span>
                  <span className="boldIt">Contact</span>
                </p>
                <p className="onTop3">
                  {" "}
                  <i className="fa fa-envelope-o" aria-hidden="true"></i>{" "}
                  <span className="boldIt">
                    <a href="mailto:info@visimo.ai" style={{ color: "white" }}>
                      INFO@VISIMO.AI
                    </a>
                  </span>
                </p>
                <p className="onTop3">
                  <i
                    className="fa fa-volume-control-phone"
                    aria-hidden="true"
                  ></i>{" "}
                  <span className="boldIt">
                    <a href="tel:4126996900" style={{ color: "white" }}>
                      412.699.6900
                    </a>
                  </span>
                </p>
              </center>
            </span>

            {/* for small screen on left panel */}
            <span className="smallScreen">
              {/* for non mobile view on small left panel */}
              <span className="mobileNo">
                <center>
                  <h1 className="onTop">Get In</h1>
                  <h1 className="onTop">
                    <span className="white-outline">Touch</span>
                    <span className="period">. </span>
                  </h1>
                  <p className="onTop3">
                    {" "}
                    <span className="period2 onTop2">_ </span>
                    <span className="boldIt">Contact</span>
                  </p>
                  <p className="onTop3">
                    {" "}
                    <i className="fa fa-envelope-o" aria-hidden="true"></i>{" "}
                    <span className="boldIt">
                      <a
                        href="mailto:info@visimo.ai"
                        style={{ color: "white" }}
                      >
                        INFO@VISIMO.AI
                      </a>
                    </span>
                  </p>
                  <p className="onTop3">
                    <i
                      className="fa fa-volume-control-phone"
                      aria-hidden="true"
                    ></i>{" "}
                    <span className="boldIt">
                      <a href="tel:4126996900" style={{ color: "white" }}>
                        412.699.6900
                      </a>
                    </span>
                  </p>
                </center>
              </span>

              {/* for mobile view on small left panel */}
              <span
                className="mobileYes"
                style={{ position: "relative", "padding-top": "30px" }}
              >
                <span
                  style={{
                    position: "absolute",
                    margin: "0",
                    padding: "0",
                    marginTop: "175px",
                    marginLeft: "20px",
                  }}
                >
                  <h1>Get In</h1>
                  <h1>
                    <span className="white-outline">Touch</span>
                    <span className="period">. </span>
                  </h1>
                  <p>
                    {" "}
                    <span className="period2 onTop2">_ </span>
                    <span className="boldIt">Contact</span>
                  </p>
                  <p>
                    {" "}
                    <i className="fa fa-envelope-o" aria-hidden="true"></i>{" "}
                    <span className="boldIt">
                      <a
                        href="mailto:info@visimo.ai"
                        style={{ color: "white" }}
                      >
                        INFO@VISIMO.AI
                      </a>
                    </span>
                  </p>
                  <p>
                    <i
                      className="fa fa-volume-control-phone"
                      aria-hidden="true"
                    ></i>{" "}
                    <span className="boldIt">
                      <a href="tel:4126996900" style={{ color: "white" }}>
                        412.699.6900
                      </a>
                    </span>
                  </p>
                  <span>
                    <p style={{ padding: "1px", margin: "0" }}>
                      {" "}
                      Have questions? Interested in a potential
                    </p>
                    <p style={{ padding: "1px", margin: "0" }}>
                      {" "}
                      oppurtunity? Reach out either via phone or email, both{" "}
                    </p>
                    <p style={{ padding: "1px", margin: "0" }}>
                      {" "}
                      listed below, or email, both listed below, or use our
                    </p>
                    <p style={{ padding: "1px", margin: "0" }}>
                      {" "}
                      friendly contact form on this page!{" "}
                    </p>
                  </span>
                </span>
              </span>
            </span>
            <a
              href="https://www.google.com/maps/place/520+E+Main+St+%23200,+Carnegie,+PA+15106/@40.4109844,-80.0827675,17z/data=!3m1!4b1!4m5!3m4!1s0x8834f634b2c8b289:0xb20bb3a814cbb481!8m2!3d40.4109844!4d-80.0805788"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className="btn waves-effect waves-light buttonBackground"
                type="submit"
                name="action"
              >
                <span className="buttonText">Get Directions{"  "}</span>
                <i className="fa fa-map-marker" aria-hidden="true"></i>
              </button>
            </a>
          </div>

          {/* for big screen on right panel */}
          <div className="bigScreen">
            <div className=" col m1 whiteBlock"></div>
            <div className=" col s12 m7 whiteBlock">
              <p className="sideOver" style={{ margin: "0", padding: "0" }}>
                {" "}
                Have questions? Interested in a potential opportunity?
              </p>
              <p className="sideOver" style={{ margin: "0", padding: "0" }}>
                {" "}
                Reach out either via phone or email, both listed below, or
              </p>
              <p className="sideOver" style={{ margin: "0", padding: "0" }}>
                {" "}
                use our friendly contact form on this page!
              </p>
              <div className="fixingForm">
                <Form />
              </div>
              <img src={SidePic} className="sidePic" alt="Side Pic" />
            </div>
          </div>

          {/* for small screen on right panel */}
          <div className="smallScreen">
            <div
              className=" col s12 m8 whiteBlock"
              style={{ position: "relative" }}
            >
              <span className="mobileNo">
                <p className="sideOver" style={{ margin: "0", padding: "0" }}>
                  {" "}
                  Have questions? Interested in a potential opportunity?
                </p>
                <p className="sideOver" style={{ margin: "0", padding: "0" }}>
                  {" "}
                  Reach out either via phone or email, both listed below, or
                </p>
                <p className="sideOver" style={{ margin: "0", padding: "0" }}>
                  {" "}
                  use our friendly contact form on this page!
                </p>
              </span>
              <div
                className="fixingForm mobileNo"
                style={{ marginTop: "-50px" }}
              >
                <Form />
              </div>
              <div
                className="fixingForm mobileYes"
                style={{ marginTop: "-50px" }}
              >
                <SmallForm />
              </div>
              <span className="mobileNo">
                <img src={SidePic} className="sidePic" alt="Side Pic" />
              </span>
              {/* bottom wave */}
              <span className="mobileYes">
                <img
                  src={Wave}
                  style={{
                    position: "absolute",
                    height: "400px",
                    width: "90%",
                    bottom: "-10px",
                    right: "0",
                  }}
                  alt="Wave"
                />
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default connect(null, actions)(Contact);
