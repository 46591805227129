import React from "react";
import { connect } from "react-redux";
import * as actions from "./../../actions";
import "./nonFeatured.css";
import { Link } from "react-router-dom";

const NonFeatured = ({ articleData }) => {
  const articleList = articleData.map((article, index) => {
    return (
      <>
        {!article.featured && (
          <div>
            <li key={article.id}>
              <div
                className="row"
                style={{
                  position: "relative",
                  margin: "0",
                  padding: "0",
                  marginTop: "70px",
                }}
              >
                {/* white space on left */}
                <span className="mobileNo">
                  <div
                    className="col s1"
                    style={{ position: "relative", height: "200px" }}
                  ></div>
                </span>

                {/* id */}
                <span className="mobileNo">
                  <div
                    className="col s1"
                    style={{ position: "relative", height: "200px" }}
                  >
                    <p
                      style={{
                        width: "100%",
                        position: "absolute",
                        color: "#01AED6",
                        right: "0",
                      }}
                    >
                      {" "}
                      {index}
                    </p>
                  </div>
                </span>

                {/* article title */}
                <span className="mobileNo">
                  <div
                    className="col s3"
                    style={{ position: "relative", height: "200px" }}
                  >
                    <Link
                      to={`/media/${article.id}`}
                      style={{ color: "inherit" }}
                    >
                      <h5
                        style={{
                          width: "100%",
                          position: "absolute",
                          left: "0",
                        }}
                      >
                        {article.title}
                      </h5>
                    </Link>
                  </div>
                </span>

                {/* middle white space */}
                <span className="mobileNo">
                  <div
                    className="col s1"
                    style={{ position: "relative", height: "200px" }}
                  ></div>
                </span>

                {/* article description */}
                <span className="mobileNo">
                  <div
                    className="col s4"
                    style={{ position: "relative", height: "200px" }}
                  >
                    <p
                      style={{
                        width: "100%",
                        position: "relative",
                        "text-align": "justify",
                      }}
                    >
                      {" "}
                      {article.description}
                    </p>
                    <p
                      style={{
                        width: "100%",
                        position: "relative",
                        color: "grey",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      {article.date}
                    </p>
                  </div>
                </span>

                {/* plus sign */}
                <span className="mobileNo">
                  <div
                    className="col s1"
                    style={{ position: "relative", height: "200px" }}
                  >
                    <Link to={`/media/${article.id} `}>
                      <p
                        style={{
                          fontSize: "40px",
                          position: "absolute",
                          top: "-45px",
                          color: "red",
                          right: "0",
                        }}
                        className="spinner"
                      >
                        {" "}
                        +{" "}
                      </p>
                    </Link>
                  </div>
                </span>

                {/* white space on right */}
                <span className="mobileNo">
                  <div
                    className="col s1"
                    style={{ position: "relative", height: "200px" }}
                  ></div>
                </span>

                <span className="mobileYes">
                  <div
                    className="col s1"
                    style={{ position: "relative", height: "50px" }}
                  ></div>

                  <div
                    className="col s5"
                    style={{ position: "relative", height: "50px" }}
                  >
                    <p
                      style={{
                        width: "100%",
                        position: "absolute",
                        color: "#01AED6",
                        right: "0",
                      }}
                    >
                      {" "}
                      {index}
                    </p>
                  </div>

                  {/*plus sign */}
                  <div
                    className="col s5"
                    style={{ position: "relative", height: "50px" }}
                  >
                    <Link to={`/media/${article.id}`}>
                      <p
                        style={{
                          fontSize: "40px",
                          position: "absolute",
                          top: "-45px",
                          color: "red",
                          right: "0",
                        }}
                        className="spinner"
                      >
                        {" "}
                        +{" "}
                      </p>
                    </Link>
                  </div>

                  <div
                    className="col s1"
                    style={{ position: "relative", height: "50px" }}
                  ></div>

                  <div
                    className="col s1"
                    style={{ position: "relative", height: "100px" }}
                  ></div>
                  <div
                    className="col s10"
                    style={{ position: "relative", height: "100px" }}
                  >
                    <Link
                      to={`/media/${article.id}`}
                      style={{ color: "inherit" }}
                    >
                      <h5
                        style={{
                          width: "100%",
                          position: "absolute",
                          left: "0",
                        }}
                      >
                        {article.title}
                      </h5>
                    </Link>
                  </div>
                  <div
                    className="col s1"
                    style={{ position: "relative", height: "100px" }}
                  ></div>

                  <div
                    className="col s1"
                    style={{ position: "relative", height: "250px" }}
                  ></div>
                  <div
                    className="col s10"
                    style={{ position: "relative", height: "250px" }}
                  >
                    <p
                      style={{
                        width: "100%",
                        position: "relative",
                        "text-align": "justify",
                      }}
                    >
                      {" "}
                      {article.description}
                    </p>
                    <p
                      style={{
                        width: "100%",
                        position: "relative",
                        color: "grey",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      {article.date}
                    </p>
                  </div>
                  <div
                    className="col s1"
                    style={{ position: "relative", height: "250px" }}
                  ></div>
                </span>
              </div>
            </li>
          </div>
        )}
      </>
    );
  });

  return (
    <>
      <div>
        <ul>{articleList}</ul>
      </div>
    </>
  );
};

export default connect(null, actions)(NonFeatured);
