import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import MissionStatement from "./missionStatement";
import Services from "./services";
import Process from "./process";
import Approach from "./approach";
import GetInTouch from "./getInTouch";
import Awards from "./awards";
import "./middle.css";
import { Component } from "react";
import Testimony from "./testimonies";

class Middle extends Component {
  render() {
    return (
      <div className="animate__animated animate__fadeIn">
        <MissionStatement />
        <Services />
        <Approach />
        <Testimony />
        <Process />
        <Awards />
        <GetInTouch />
      </div>
    );
  }
}

export default connect(null, actions)(Middle);
