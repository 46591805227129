import React, { Component } from "react";
import NavBar from "../contactPage/navBar/navBar.js";
import Footer from "../footer/footer";
import SidePic from "../images/VISIMO_Site_Contact-09.webp";
import image from "../images/approach.webp";
import Waves from "../images/VISIMO_Digital_Wave_White.webp";
import "./careers.css";
import Diversity from "./diversityStatement";

class Careers extends Component {
  constructor(props) {
    super();
    this.state = {
      studies: [],
    };
  }

  render() {
    return (
      <>
        <div>
          <NavBar />
          <div className="row">
            {/* for big screen on left panel */}
            <div
              className="col s12 m4 panel"
              style={{
                position: "relative",
                backgroundImage: `linear-gradient(
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)
            ), url(${image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                color: "#FAFAFF",
                minHeight: "750px",
                minWidth: "40%",
                width: "auto",
                margin: 0,
                padding: 0,
              }}
            >
              <span>
                <center>
                  <h1 className="onTop">Join Our</h1>
                  <h1 className="onTop">
                    <span className="white-outline">Team</span>
                    <span className="period">. </span>
                  </h1>
                </center>
              </span>
            </div>

            {/* for small screen on left panel */}
            <span className="mobileYes">
              <div style={{ margin: "auto 20px" }}>
                <br />
                <h6 style={{ paddingTop: "750px" }}>
                  If you think you belong at VISIMO, we want to hear from you.{" "}
                </h6>
                <p>
                  {" "}
                  We specialize in custom web applications, artificial
                  intelligence, and machine learning models. VISIMO is focused
                  on human-centered analytics, deploying solutions into areas
                  ripe for disruptive innovation.
                </p>
                <div
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    textAlign: "left",
                  }}
                >
                  <p style={{ display: "list-item", listStyleType: "disc" }}>
                    VISIMO has been recognized by the Pittsburgh Business Times
                    as a 2020 Best Places To Work, and as a 2021 Military Spouse
                    Friendly Employer
                  </p>
                  <p style={{ display: "list-item", listStyleType: "disc" }}>
                    We offer competitive benefits including unlimited PTO and $0
                    premium health care
                  </p>
                </div>

                <Diversity />

                <h6 style={{ marginBottom: "50px" }}>
                  {" "}
                  Please review the open positions available through{" "}
                  <a
                    target="blank"
                    href="https://www.indeed.com/cmp/Visimo?from=vjcmpinfo&tk=1ej2j266mu2f6801&fromjk=339d0181a3f08984&attributionid=vjcmpinfo"
                    style={{ color: "red", textDecorationLine: "underline" }}
                  >
                    {" "}
                    Indeed.{" "}
                  </a>
                </h6>
              </div>
            </span>

            {/* for big screen on right panel */}
            <div className="bigScreen">
              <div className="col m1 whiteBlock"></div>
              <div
                className="col s12 m5"
                style={{ paddingTop: "100px", paddingBottom: "30px" }}
              >
                <h6 style={{ marginBottom: "20px" }}>
                  If you think you belong at VISIMO, we want to hear from you.
                </h6>
                <div>
                  <p style={{ margin: "0", padding: "0" }}>
                    {" "}
                    We specialize in custom web applications, artificial
                    intelligence, and machine learning models. VISIMO is focused
                    on human-centered analytics, deploying solutions into areas
                    ripe for disruptive innovation.
                  </p>
                </div>
                <div style={{ paddingLeft: "20px" }}>
                  <p style={{ display: "list-item", listStyleType: "disc" }}>
                    VISIMO has been recognized by the Pittsburgh Business Times
                    as a 2020 Best Places To Work, and as a 2021 Military Spouse
                    Friendly Employer
                  </p>
                  <p style={{ display: "list-item", listStyleType: "disc" }}>
                    We offer competitive benefits including unlimited PTO and $0
                    premium health care
                  </p>
                </div>
                <div>
                  <Diversity />
                </div>
                <h6 style={{ margin: "0", padding: "0" }}>
                  {" "}
                  Please review the open positions available through{" "}
                  <a
                    target="blank"
                    rel="noopener noreferrer"
                    href="https://www.indeed.com/cmp/Visimo?from=vjcmpinfo&tk=1ej2j266mu2f6801&fromjk=339d0181a3f08984&attributionid=vjcmpinfo"
                    style={{ color: "red", textDecorationLine: "underline" }}
                  >
                    {" "}
                    Indeed.{" "}
                  </a>{" "}
                </h6>
                <img
                  src={SidePic}
                  style={{ top: "0%" }}
                  className="sidePic"
                  alt="Side"
                />
                <img
                  src={Waves}
                  style={{
                    top: "0%",
                    left: "40%",
                    position: "absolute",
                    zIndex: -1,
                  }}
                  alt="Waves"
                />
              </div>
            </div>

            {/* for small screen on right panel */}
            <div className="smallScreen">
              {/* for non mobile view on small right panel */}
              <span className="mobileNo">
                <div
                  className="col s12 m7"
                  style={{ paddingTop: "100px", paddingBottom: "20px" }}
                >
                  <h6 style={{ margin: "0", paddingLeft: "5%" }}>
                    {" "}
                    If you think you belong at VISIMO, we want to hear from you.
                  </h6>
                  <p style={{ margin: "0", paddingLeft: "5%" }}>
                    {" "}
                    We specialize in custom web applications, artificial
                    intelligence, and machine learning models. VISIMO is focused
                    on human-centered analytics, deploying solutions into areas
                    ripe for disruptive innovation.
                  </p>
                  <div style={{ paddingLeft: "50px" }}>
                    <p style={{ display: "list-item", listStyleType: "disc" }}>
                      VISIMO has been recognized by the Pittsburgh Business
                      Times as a 2020 Best Places To Work, and as a 2021
                      Military Spouse Friendly Employer
                    </p>
                    <p style={{ display: "list-item", listStyleType: "disc" }}>
                      We offer competitive benefits including unlimited PTO and
                      $0 premium health care
                    </p>
                  </div>
                  <div style={{ margin: "0", paddingLeft: "5%" }}>
                    <div>
                      <Diversity />
                    </div>
                  </div>
                  <h6 style={{ margin: "0", paddingLeft: "5%" }}>
                    {" "}
                    Please review the open positions available through{" "}
                    <a
                      target="blank"
                      href="https://www.indeed.com/cmp/Visimo?from=vjcmpinfo&tk=1ej2j266mu2f6801&fromjk=339d0181a3f08984&attributionid=vjcmpinfo"
                      style={{ color: "red", textDecorationLine: "underline" }}
                    >
                      {" "}
                      Indeed.
                    </a>
                  </h6>
                </div>
              </span>
            </div>
          </div>
          <Footer style={{ zIndex: 10, position: "relative" }} />
        </div>
      </>
    );
  }
}

export default Careers;
