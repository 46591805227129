import axios from "axios";
import {
  CONTACT_SUCCESS,
  CONTACT_ERROR,
  FETCH_MEDIA,
  FETCH_AWARDS_RECTANGLE,
  FETCH_AWARDS_SQUARE,
  FETCH_CLIENTS,
  FETCH_PARTNERS,
} from "./types";
import { apiUrl } from "../config";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

export const postContact = (message) => (dispatch) => {
  axios
    .post(`${apiUrl}/contacts/`, message)
    .then(function (response) {
      dispatch({ type: CONTACT_SUCCESS, payload: response });
    })
    .catch(function (error) {
      dispatch({ type: CONTACT_ERROR, payload: error });
    });
};

export const fetchEmployees = (message) => (dispatch) => {
  axios
    .get(`${apiUrl}/media/`)
    .then(function (response) {
      dispatch({ type: FETCH_MEDIA, payload: response });
    })
    .catch(function (error) {});
};

export const fetchLogos = () => (dispatch) => {
  axios
    .get(`${apiUrl}/logos/category/1`)
    .then(function (response) {
      dispatch({ type: FETCH_PARTNERS, payload: response.data });
    })
    .catch(function (error) {});
  axios
    .get(`${apiUrl}/logos/category/2`)
    .then(function (response) {
      dispatch({ type: FETCH_CLIENTS, payload: response.data });
    })
    .catch(function (error) {});
  axios
    .get(`${apiUrl}/logos/category/3`)
    .then(function (response) {
      dispatch({ type: FETCH_AWARDS_SQUARE, payload: response.data });
    })
    .catch(function (error) {});
  axios
    .get(`${apiUrl}/logos/category/4`)
    .then(function (response) {
      dispatch({ type: FETCH_AWARDS_RECTANGLE, payload: response.data });
    })
    .catch(function (error) {});
};
